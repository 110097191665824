import React from "react"
import Slider from "react-slick"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
export default () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
  }

  const data = useStaticQuery(graphql`
    query {
      eins: file(relativePath: { eq: "carousel/eins.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zwei: file(relativePath: { eq: "carousel/zwei.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drei: file(relativePath: { eq: "carousel/drei.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vier: file(relativePath: { eq: "carousel/vier.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="container">
      <Slider {...settings}>
        <div className="is-relative"
>
          <Link to="/beratung/medienabhaengigkeit" >
            <Img fluid={data.eins.childImageSharp.fluid} />
            <Text>Medienabhängigkeit</Text>
          </Link>
        </div>
        <div className="is-relative">
          <Link
            to="/beratung/pathologisches-gluecksspiel"
            
          >
            <Img fluid={data.zwei.childImageSharp.fluid} />
            <Text>Glücksspielsucht</Text>
          </Link>
        </div>
        <div className="is-relative">
          <Link to="/beratung" >
            <Img fluid={data.drei.childImageSharp.fluid} />
            <Text>Probleme</Text>
          </Link>
        </div>
        <div className="is-relative">
          <Link to="/beratung/angehoerige" >
            <Img fluid={data.vier.childImageSharp.fluid} />
            <Text>Angehörige</Text>
          </Link>
        </div>
      </Slider>
    </div>
  )
}

const Text = ({ children }) => (
  <div
    style={{
      position: "absolute",
      bottom: "0px",
      width: "100%",
      padding: "1rem",
      backgroundColor: "rgba(0,0,0,0.3)",
    }}
  >
    <h6 style={{ color: "white" }} className="title is-6">
      {children}
    </h6>
  </div>
)
