import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import Carousel from "../components/carousel"
const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      hotline: file(relativePath: { eq: "home/hotline.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotlinetue: file(relativePath: { eq: "home/hotlinetue.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paritaetische: file(relativePath: { eq: "home/paritaetische.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      auge: file(relativePath: { eq: "home/auge.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nachruf: file(relativePath: { eq: "home/juergen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Arbeiteskreis gegen Spielsucht e.V." />
      <section className="section">
        <Carousel />
      </section>
      <section className="section">
        <div className="container">
          <div
            className="notification is-primary"
            style={{ display: "flex", gap: "16px" }}
          >
            <div className="icon is-large">
              <i className="fas fa-2x fa-exclamation-circle"></i>
            </div>
            <div className="content is-medium">
              <p>
                Aufgrund der aktuellen Situation kann es derzeit zu
                Verzögerungen bei der Bearbeitung Ihrer Anfrage kommen.
                <br />
                Wir bitten um Ihr Verständnis.
              </p>
              <p>
                Ihre Ansprechpartnerinnen sind: <br />
                Frau Frances Trümper Frau Christiane Heimann (Verwaltung)
              </p>
            </div>
          </div>
          {/* <div className="content">
            <h4>Jürgen Trümper</h4> <h5>05.02.1955 - 21.05.2022</h5>
            <p>Am 21.05.2022 verstarb Jürgen Trümper.</p>
            <p>
              Wir alle sind traurig. Mit ihm verlieren der Verein und die
              Mitarbeitenden einen großen Vordenker, unermüdlichen Kämpfer und
              Wegbegleiter. Der Tod von Jürgen Trümper ist ein wirklicher
              Verlust und hinterlässt eine tiefe Lücke. Es trauert seine Familie
              zusammen mit den Mitarbeitenden und Freunden und allen, die ihn
              als Persönlichkeit mochten und schätzten; für all das, was ihn als
              humor- und liebevollen, politisch denkenden und sozial handelnden
              Menschen ausgemacht hat.
            </p>
            <div className="imgResp">
              <Img fluid={data.nachruf.childImageSharp.fluid} />
            </div>
            <p>
              Ein Verlust ist sein Tod aber auch für alle, die sich in diesem
              Land gegen Glücksspielsucht engagieren und den davon Betroffenen
              Beratung und Hilfen anbieten. Die mit ihrer Arbeit versuchen, der
              Lobby der Glücksspielindustrie und einer zerrissenen Politik
              zwischen Gesundheit und Steuereinnahmen nicht nur etwas
              entgegenzusetzen, sondern auch mit Dialog und notwendiger
              Kooperation die Prävention und den Schutz gegen abhängig- und
              krankmachendes Spiel zu stärken.
            </p>
            <p> Jürgen Trümper hinterlässt ein einzigartiges Lebenswerk.</p>
            <p>
              Ohne seine Kraft wäre der Arbeitskreis gegen Spielsucht e.V. vor
              dreißig Jahren nicht entstanden. Nur eine Handvoll Menschen gab es
              damals, die diese Problematik ebenfalls gesehen und ihre
              Unterstützung gegeben haben. Anfangs ging es um die Anerkennung
              der stoffungebunden Sucht und ihrer sozialen Zerstörungskraft, um
              den Aufbau einer Beratungsstruktur und die Aufklärung der
              Gesellschaft und Politik über Missstände und Defizite. Daraus
              entstand, vor allen mit der Unterstützung des damaligen
              sozialpolitischen Sprechers der SPD-Landtagsfraktion, Wolfram
              Kuschke und der Förderung durch die Stiftung Wohlfahrtspflege NRW
              die Fachstelle Glücksspielsucht, mit ihren Standorten in Neuss,
              Herford und Unna.
            </p>
            <p>
              Heute ist der Arbeitskreis gegen Spielsucht e.V. aufgrund der
              unermüdlichen Arbeit von Jürgen Trümper Träger der
              Fachberatungsstelle Pathologisches Glücksspiel und
              Medienabhängigkeit mit angeschlossener ambulanter Rehabilitation
              sowie der Schwerpunktberatungsstelle mit dem Themenkomplex Markt
              und Medien.
            </p>
            <p>
              Gerade die Tätigkeit der Schwerpunktberatungsstelle gehört zu den
              herausragenden Leistungen Jürgen Trümpers, der Fortbildungen und
              Fachtagungen für kommunale Behörden, Polizei- und Fachkräfte der
              Prävention organisierte und regelmäßig in akribischer Kleinarbeit
              den bundesdeutschen Glücksspielmarkt in der „roten Studie“
              dokumentierte, inklusive einer Analyse der Entwicklungen. Gerade
              diese Zusammenarbeit mit den Mitarbeitenden von Ordnungs- und
              Steuerämtern, der Polizei und Steuerfahndung betrachtete Jürgen
              Trümper als wichtiges Gut, da sich hier „unsere natürlichen
              Verbündeten im Kampf gegen das (illegale) Glücksspiel“ finden.
            </p>
            <p>
              Im Laufe der Zeit entstanden bundesweit viele Therapieangebote und
              Jürgen Trümper reiste als gefragter Referent rastlos durch die
              Republik, um auf Tagungen und Foren aufzuklären, Ideen zur
              strukturellen Prävention zu entwickeln und vorzustellen, und um
              Unterstützung für unsere Forderungen zu werben. Er war der
              anerkannte Experte und Macher, aber auch der pragmatische
              Organisator und Gesprächspartner gegenüber der Politik und
              Anbieterseite, ohne Illusionen was deren Interessen anging und die
              eigenen Möglichkeiten. Manches was Jürgen Trümper als Prävention
              der Glücksspielbranche anstieß, wurde später zu einem
              selbstverständlichen Teil der Suchthilfestruktur in unserem Lande.
              Bei allem was er tat, war das Leid und die Verelendung derer, die
              an Glücksspielsucht erkrankten oder als Angehörige von der
              Glücksspielsucht betroffen waren, beständiger Antrieb weiter zu
              kämpfen.
            </p>
            <p>Er wird fehlen und ist nicht ersetzbar.</p>
            <p>
              In seinem Sinne weiterzuarbeiten ist eine schwierige Aufgabe und
              eine große Herausforderung, der wir uns stellen werden. Vieles
              wird sich nun für den Arbeitskreis ändern. Das, was Jürgen Trümper
              in seinem Leben geschaffen hat, was er vor allem in anderen
              Menschen bewegt und an Gutem bewirkt hat, wird für immer bleiben
              und so wollen wir Jürgen Trümper in guter Erinnerung behalten und
              dankbar für alles sein, was wir an ihm und mit ihm hatten.
            </p>
            <p>
              Was bleibt sind seine Werke und unsere Erinnerungen an einen
              großartigen Menschen, einen verlässlichen Ratgeber und einen
              freundlichen, bescheidenen Menschen.
            </p>
            <p>Unsere Gedanken sind bei seiner Familie.</p>
            <p>
              <b> Der Vorstand</b>
            </p>
          </div> */}
          <hr />
          <div className="columns">
            <CardColumn title="Beratung">
              <p>
                Die Fachberatungsstelle Pathologisches Glücksspiel und
                Medienabhängigkeit bietet Beratungsangebote für Menschen, die
                sich mit dem Thema beschäftigen, sich von der Thematik betroffen
                oder bedroht fühlen. Das Beratungsangebot richtet sich dabei
                gleichermaßen an Betroffene wie auch Angehörige. Die Beratung
                ist kostenfrei, unverbindlich und unterliegt der
                Schweigepflicht. Auf Wunsch kann die Beratung auch anonym
                erfolgen.
              </p>
            </CardColumn>
            <CardColumn title="Vorträge & Schulungen">
              <p>
                Die Arbeit mit und zu den Themen Pathologisches Glücksspiel und
                Medienabhängigkeit bedarf der umfassenden Information über
                Entstehung und Hintergrund der Problematik. Besonders im Bereich
                des Pathologischen Glücksspiels gehören hierzu auch gesetzliche
                und ordnungsrechtliche Grundlagen sowie die Angebotsstruktur der
                Glücksspielmedien. In einzelnen Vorträgen oder umfassenden
                Schulungen vermitteln wir diese Themen.
              </p>
            </CardColumn>
            <CardColumn title="Publikationen">
              <p>
                Der Arbeitskreis gegen Spielsucht e.V. erstellt seit 1994
                fortlaufend eine Angebotsstruktur der Geldspielgeräte in
                Spielhallen und gastronomischen Betrieben in der Bundesrepublik.
                Informationen zur Statistik / Analyse sowie über weitere
                Publikationen zum Pathologischen Glücksspiel erhalten Sie auf
                den folgenden Seite.
              </p>
            </CardColumn>
          </div>
        </div>
      </section>
      <section className="hero is-light">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div class="column is-4 is-offset-8">
                <Img fluid={data.paritaetische.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const CardColumn = ({ title, children }) => (
  <div className="column is-flex">
    <div className="card">
      <div className="card-content">
        <h5 className="title has-text-centered	 is-5">{title}</h5>
        {children}
      </div>
    </div>
  </div>
)

export default Home
